import {useNavigate} from "react-router-dom";
import AuthService from "../api/authService";
import IconLogout from "../icons/logout";

import React, {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useDispatch, useSelector} from "react-redux";
import {loginAction, logoutAction} from "../redux/authSlice";
import IconLoginBoxLine from "../icons/login";
import Cookies from "js-cookie";
import Image from "react-bootstrap/Image";
import config from "../config.json";


export default function Navigator () {
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const expand = false;
    const [show, setShow] = useState(false)
    const goToLogin =  ()=>{
        navigate('/login')
    }
    const goToAccount =  ()=>{
        navigate('/account')
    }

    const logout = async ()=>{
        const result = await AuthService.logout(navigate)
        if(result){
            dispatch(logoutAction())
            //dispatch(dropShippingAddress())
        }
    }
    return (
        <Navbar key={expand} expand={expand} className="justify-content-between">
                <Container fluid >
                    <Navbar.Text>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={()=>setShow(!show)}/>
                        <Navbar.Brand href="#" style={{marginLeft:20}}>Центр управления БВС</Navbar.Brand>
                    </Navbar.Text>
                    {auth.userProfile && <Navbar.Text>
                        { auth.userProfile.photo && <Image onClick={goToAccount} style={{marginRight:10, cursor:'pointer'}} width={45} height={45} src={config.filesPath + auth.userProfile.photo} roundedCircle />}

                        <Button variant="link" style={{color:'white'}} onClick={goToAccount}>{auth.userProfile.name}</Button>
                        <IconLogout  style={{ marginLeft:'20px'}} onClick={logout} height={'25px'} color={'white'} stroke={'white'}/>
                    </Navbar.Text>}
                    {!auth.userProfile && <Navbar.Text>
                        <IconLoginBoxLine  style={{ marginLeft:'20px'}} onClick={goToLogin} height={'30px'} color={'white'} stroke={'white'}/>
                    </Navbar.Text>}
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="start"
                        show={show}
                        onHide={()=>setShow(!show)}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                Меню
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                {/*<Nav.Link onClick={()=>{navigate('/home');setShow(!show)}}>Home</Nav.Link>*/}
                                {/*<Nav.Link onClick={()=>{navigate('/ecological-education');setShow(!show)}}>Ecological education</Nav.Link>*/}
                                {/*<Nav.Link onClick={()=>{navigate('/ecological-situation');setShow(!show)}}>Ecological situation</Nav.Link>*/}
                                {/*<Nav.Link onClick={()=>{navigate('/ecological-maps');setShow(!show)}}>Ecological maps</Nav.Link>*/}
                                {/*<Nav.Link onClick={()=>{navigate('/examples');setShow(!show)}}>Examples of good influence</Nav.Link>*/}
                                {/*<Nav.Link onClick={()=>{navigate('/footprint');setShow(!show)}}>Carbon footprint calculator</Nav.Link>*/}
                                {/*<Nav.Link onClick={()=>{navigate('/video');setShow(!show)}}>Video lessons</Nav.Link>*/}
                                <Nav.Link onClick={()=>{navigate('/drones');setShow(!show)}}>Устройства</Nav.Link>
                                <Nav.Link onClick={()=>{navigate('/missions');setShow(!show)}}>Полетные задания</Nav.Link>
                                <Nav.Link onClick={()=>{navigate('/detectors');setShow(!show)}}>НСД "НЕБО"</Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
    );
}
