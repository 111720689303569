import React, {useState, useEffect} from "react";

import Modal from 'react-bootstrap/Modal';
import FormBuilder from "../../../components/form";
import {showError} from "../../../redux/errorSlice";
import DetectorsService from "../../../api/detectorsService";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

const fields = [
    {
        propertyName:'name',
        name:'Название',
        type:'text',
        value:''
    },
    {
        propertyName:'number',
        name:'Номер',
        type:'text',
        value:''
    },
    {
        propertyName:'lat',
        name:'Долгота',
        type:'text',
        value:''
    },
    {
        propertyName:'lon',
        name:'Широта',
        type:'text',
        value:''
    },
    {
        propertyName:'alt',
        name:'Высота',
        type:'text',
        value:''
    },
    {
        propertyName:'frequency1_from',
        name:'Частота1 от',
        type:'text',
        value:''
    },
    {
        propertyName:'frequency1_to',
        name:'Частота1 до',
        type:'text',
        value:''
    },
    {
        propertyName:'frequency2_from',
        name:'Частота2 от',
        type:'text',
        value:''
    },
    {
        propertyName:'frequency2_to',
        name:'Частота2 до',
        type:'text',
        value:''
    },
    {
        propertyName:'frequency3_from',
        name:'Частота3 от',
        type:'text',
        value:''
    },
    {
        propertyName:'frequency3_to',
        name:'Частота3 до',
        type:'text',
        value:''
    },
    {
        propertyName:'frequency4_from',
        name:'Частота4 от',
        type:'text',
        value:''
    },
    {
        propertyName:'frequency4_to',
        name:'Частота4 до',
        type:'text',
        value:''
    },
]
const PointDetectorEditModalForm = ({ point, onSubmit, onClose, show, setShow, oldValues, editMode, setPosition }) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const save = async (values)=>{
        // if (!selectedType){
        //     dispatch(showError("Укажите тип радара"))
        // } else if (selectedType === "radio" && !position){
        //     dispatch(showError("Укажите позицию радара"))
        // } else {
            const { current, ...restValues} = values;
            const lat = values.lat ? values.lat : current?.lat
        const lon = values.lon ? values.lon : current?.lon

            console.log(values, oldValues)
            let body = {
                ...oldValues,
                ...restValues,
                position: {lat: parseFloat(lat), lon: parseFloat(lon)},
            }
        console.log(body);
        setPosition({lat, lon, alt: 0})
        const result = await DetectorsService.merge( navigate, body);

        if(result.status == 'error') {
            if(result.errors){
                result.errors.forEach((error)=>dispatch(showError(error.msg)))
            }else
                dispatch(showError(result.message))
        }
        // }
        onClose()
    }
    return <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>{ editMode ? 'Изменить данные точки' : "Просмотр данных точки" }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormBuilder fields={fields} values={{ ...oldValues, ...point}} buttonName={ 'Сохранить'}  submit={save}/>
        </Modal.Body>
    </Modal>
}
export default PointDetectorEditModalForm
