//const basePath = 'http://192.168.0.195:3000/api';

const version = '';
const config = require('../config.json');
const basePath = config.apiServerPath;

export default {
  login: `${basePath}` + 'auth/login',
  userLogout: `${basePath}${version}/` + 'auth/logout',
  authRefresh: `${basePath}${version}/` + 'auth/refresh',
  userActivate: `${basePath}${version}/` + 'auth/activate',
  sendRecoveryCode: `${basePath}${version}/` + 'auth/recovery/send-code',
  checkRecoveryCode: `${basePath}${version}/` + 'auth/recovery/check-code',
  changePassword: `${basePath}${version}/` + 'auth/recovery/change-password',
  userRegister: `${basePath}${version}/` + 'auth/register',
  userLogin: `${basePath}${version}/` + 'auth/login',

  getUsers: `${basePath}${version}/` + 'users',
  userEdit:`${basePath}${version}/` + 'user/edit',

  getDrones: `${basePath}${version}/` + 'drones',
  getDrone: `${basePath}${version}/` + 'drone',
  getDroneByNumber: `${basePath}${version}/` + 'drone_by_number',

  droneEdit:`${basePath}${version}/` + 'drone/merge',
  deleteDrone:`${basePath}${version}/` + 'drone/delete',
  getDronePosition: `${basePath}${version}/` + 'position',

  getDroneMav: `${basePath}${version}/` + 'mav',

  getDroneStatus: `${basePath}${version}/` + 'status',

  getMissions: `${basePath}${version}/` + 'missions',
  getMission: `${basePath}${version}/` + 'mission',
  mergeMission:`${basePath}${version}/` + 'mission/merge',
  deleteMission:`${basePath}${version}/` + 'mission/delete',

  getDetectors: `${basePath}${version}/` + 'detectors',
  getDetector: `${basePath}${version}/` + 'detector',
  mergeDetector:`${basePath}${version}/` + 'detector/merge',
  deleteDetector:`${basePath}${version}/` + 'detector/delete',
  getTargets:`${basePath}${version}/` + 'detections',


  getUser: `${basePath}${version}/` + 'user/get',

};
